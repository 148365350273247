import Button from "../../components/Button";
import searchIcon from "../../../assets/searchIcon.svg";
import lines from "../../../assets/lines.svg";
import blocks from "../../../assets/blocks.svg";
import { useState, useEffect } from "react";
import RecentProjects from "./RecentProjects";
import { Upload, Spin, Menu } from "antd";
import { addOrUpdateProject, getAllProjects } from "../fakeApi";
import { useUserIdentity } from "../../../hooks/useUserIdentity";
import { PlusCircleOutlined } from "@ant-design/icons";
import { AddProjectModal } from "./AddProjectModal";

const ProjectsMain = () => {
  const [projects, setProJects] = useState([]);
  const [search, setSearch] = useState("");
  const [projectsView, setProjectsView] = useState("grid");
  const { canAccessDiagram } = useUserIdentity();
  const [isAddModalopen, setIsAddModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedEndpoint, setSelectedEndpoint] = useState(""); // Track the selected endpoint
  const [file, setFile] = useState(null); // State to hold the file
  const [isMenuVisible, setIsMenuVisible] = useState(false); // Track menu visibility

  const endpoints = [
    {
      key: "enhance",
      label: " Enhance Code",
      description:
        "This will improve the code and generate a unit test for all the classes.",
      url: "https://codeyai-cont-app.proudocean-7b32e3d1.centralus.azurecontainerapps.io/reversEng/enhance",
    },
    {
      key: "GenUnitTest",
      label: " Gen UnitTests",
      description:
        "This generate all unit test for all classes in the project.",
      url: "https://codeyai-cont-app.proudocean-7b32e3d1.centralus.azurecontainerapps.io/reversEng/enhance",
    },
    {
      key: "javaDocs",
      label: " Java Docs ",
      description:
        "This will replace the code in each function with pseudocode that explains the logic of the code.",
      url: "https://codeyai-cont-app.proudocean-7b32e3d1.centralus.azurecontainerapps.io/reversEng/javaDocs",
    },
    {
      key: "jsonDocs",
      label: " Json Docs",
      description:
        "This will generate a JSON file that shows the structure of the project with pseudocode in each function.",
      url: "https://codeyai-cont-app.proudocean-7b32e3d1.centralus.azurecontainerapps.io/reversEng/jsonDocs",
    },
    {
      key: "BootToFlux",
      label: " Convert Springboot to Webflux [Beta version]",
      description:
        "This generate all unit test for all classes in the project.",
      url: "https://codeyai-cont-app.proudocean-7b32e3d1.centralus.azurecontainerapps.io/reversEng/bootToFlux",
    },
    {
      key: "FluxToBoot",
      label: " Convert Webflux to Springboot [Beta version]",
      description:
        "This generate all unit test for all classes in the project.",
      url: "https://codeyai-cont-app.proudocean-7b32e3d1.centralus.azurecontainerapps.io/reversEng/fluxToBoot",
    },
    {
      key: "Spring_vertx",
      label: " Convert Springboot to Vert.x [Beta version]",
      description:
        "Convert Springboot project to a Vert.x project.",
      url: "https://codeyai-cont-app.proudocean-7b32e3d1.centralus.azurecontainerapps.io/reversEng/springToVertx",
    },
    {
      key: "Vertx_spring",
      label: " Convert Vert.x to Springboot [Beta version]",
      description:
        "Convert Vert.x project to Springboot project",
      url: "https://codeyai-cont-app.proudocean-7b32e3d1.centralus.azurecontainerapps.io/reversEng/vertxToSpring",
    },


  ];

  const getProjects = () => {
    if (canAccessDiagram) setProJects(getAllProjects()?.reverse());
  };

  const handleFileUpload = () => {
    if (!file) {
      console.error("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    setLoading(true); // Start loading state

    // Use the selected endpoint to send the file
    const endpoint = selectedEndpoint;

    fetch(endpoint, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("userData")).token
        }`,
      },
    })
      .then(async (response) => {
        if (response.ok) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "project.zip";
          link.click();
          window.URL.revokeObjectURL(url);
          console.log("File downloaded successfully");
          getProjects();
        } else {
          console.error("Failed to upload file", response.statusText);
        }
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      })
      .finally(() => {
        setLoading(false);
        setFile(null); // Reset file state
      });
  };

  useEffect(() => {
    getProjects();
  }, [canAccessDiagram]);

  const handleMenuClick = (endpointUrl) => {
    setSelectedEndpoint(endpointUrl);
    document.getElementById("fileUploadInput").click(); // Trigger file input click
    setIsMenuVisible(false); // Hide the menu after selection
  };

  const handleFileChange = (info) => {
    // This function is triggered when the file is selected
    setFile(info.file); // Store the file in state
    handleFileUpload(); // Call the upload function
  };

  const toggleMenuVisibility = () => {
    setIsMenuVisible(!isMenuVisible); // Toggle menu visibility
  };

  return (
    <>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 1000,
          }}
        >
          <Spin size="large" />
        </div>
      )}
      <main className="flex flex-col grow max-w-full w-[50%]">
        <div className="flex justify-between items-center gap-[24px] sm:gap-0 border-b">
          <p className="font-medium text-[20px] pl-8">My Projects</p>
          <div className="flex items-center gap-8 flex-col sm:flex-row py-2 pr-8 ">
            {canAccessDiagram && (
              <Button
                onClick={toggleMenuVisibility}
                className={`btn h-10 element-center gap-[10px] rounded-lg py-[10px] px-5`}
              >
                Upload Project
              </Button>
            )}

            <Button
              onClick={() => setIsAddModalOpen(true)}
              className={`btn h-10 element-center gap-[10px] rounded-lg py-[10px] px-5`}
            >
              <span>
                <PlusCircleOutlined />
              </span>
              <span className="text-[10px] sm:text-[14px] font-medium">
                New Project
              </span>
            </Button>
            {canAccessDiagram && (
              <Upload
                showUploadList={false}
                beforeUpload={(file) => {
                  setFile(file); // Store the file in state
                  return false; // Prevent automatic upload
                }}
                onChange={handleFileChange} // Call this when file changes
              >
                <input
                  type="file"
                  id="fileUploadInput"
                  style={{ display: "none" }} // Hide the file input
                />
                <Button className="h-10 bg-[#EBF1FF] rounded-lg py-[10px] px-5 text-gray-600 font-medium text-[14px]">
                  Import Diagram
                </Button>
              </Upload>
            )}
          </div>
        </div>

        {/* Floating Menu */}
        {isMenuVisible && (
          <div
            className="floating-menu"
            style={{
              position: "fixed",
              top: "30%",
              left: "55%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              border: "2px solid #F4F5F7", // Thicker border
              borderRadius: "8px",
              boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
              zIndex: 9999,
              padding: "10px 20px",
              width: "450px", // Set a fixed width for the menu
              height: "380px",
            }}
          >
            <Menu style={{ border: "none", boxShadow: "none" }}>
              {endpoints.map((endpoint) => (
                <Menu.Item
                  key={endpoint.key}
                  onClick={() => handleMenuClick(endpoint.url)}
                >
                  <div>
                    <strong style={{ color: "#007A", fontSize: "18px" }}>
                      {endpoint.label}
                    </strong>{" "}
                    {/* Increase title font size */}
                    <p
                      style={{ margin: "0", fontSize: "14px", color: "black" }}
                    >
                      {endpoint.description}
                    </p>{" "}
                    {/* Increase description font size */}
                  </div>
                </Menu.Item>
              ))}
            </Menu>
          </div>
        )}

        {canAccessDiagram && (
          <div className="flex flex-col lg:flex-row gap-4 lg:gap-0 items-center justify-between px-8 py-8">
            <div className="flex gap-4 border rounded-lg items-center px-4 py-[14px] w-full sm:w-[360px]">
              <span>
                <img src={searchIcon} alt="searchIcon" />
              </span>
              <input
                placeholder="Search"
                className="outline-none w-full max-w-full"
                onChange={(e) => setSearch(e.target?.value)}
              />
            </div>
            <div className="flex gap-10 items-center">
              <div className="flex gap-4 [&>_.active]:bg-[#F4F5F7]">
                <span
                  onClick={() => setProjectsView("grid")}
                  className={
                    projectsView === "grid"
                      ? "active rounded"
                      : "cursor-pointer"
                  }
                >
                  <img src={blocks} alt="blocks" />
                </span>
                <span
                  onClick={() => setProjectsView("list")}
                  className={
                    projectsView === "list"
                      ? "active rounded"
                      : "cursor-pointer"
                  }
                >
                  <img src={lines} alt="lines" />
                </span>
              </div>
            </div>
          </div>
        )}
        <RecentProjects
          getProjects={getProjects}
          projects={projects?.filter?.((project) =>
            search ? project?.metaData.name?.includes(search) : true
          )}
          setIsAddModalOpen={setIsAddModalOpen}
          projectsView={projectsView}
        />
      </main>
      <AddProjectModal isOpen={isAddModalopen} setIsOpen={setIsAddModalOpen} />
    </>
  );
};

export default ProjectsMain;
