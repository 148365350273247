export const options = [
  { label: "Java Maven Project", value: "java-maven-project" },
  { label: "Spring Boot Project", value: "spring-boot-project" },
  // { label: 'android', value: 3 },

  // { label: "C# Project", value: 4, disabled: true },
  // { label: ".net Project", value: 5, disabled: true },
  // { label: "React Project", value: 5, disabled: true },
  // { label: ".net core", value: 6 },

  // {
  //   label: "C++",
  //   options: [{ label: "core C++", value: 7 }],
  // },
];
